import './portfolio1.css'
import { useState } from 'react'
import { faGithub} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faVideo } from '@fortawesome/free-solid-svg-icons';
const Portfolio1 = ()=>{
    const [vidMedia, setVidMedia] = useState(false)
    const closeVideo = ()=>{
        const vid = document.getElementById('video');
        setVidMedia(!vidMedia);
        vid.pause();
    }
    const openVideo = () =>{
        
        setVidMedia(!vidMedia)
    }
    //the function that opens video 2
    const openVideo2 = ()=>{
        const vid = document.getElementById('video');
        vid.setAttribute('src', 'DemoNMS.mp4');

        setVidMedia(!vidMedia);
    }
    //the function that opens video3
    const openVideo3 = ()=>{
        const vid = document.getElementById('video');
        vid.setAttribute('src', 'DemoSMS.mp4');
        setVidMedia(!vidMedia);
    }
    return(
         <section className='port-container' id='portfolio'>
            <div className='port-header'>
                <h2>Portfolio</h2>
            </div>
            <div className='card-container'>
                <div className='card'>
                    <div className='card-header'>
                        Health Advisory and health content management system
                    </div>
                    <div className='card-body'>
                        <span className='title'>Tech stack used:</span>
                        <p>  EJS Templating Engine, CSS, Express JS, MySQL Database and MVC design pattern.</p>
                        <span className='title'>Features:</span>
                        <p> The system stores premium videos and blogs posted by professionals which is viewed only by subscribed users.
                           The admin confirms the post of the professionals and manages the subscribed users and contents of the system. 
                           The subscription of the user is made by sending SMS to 8375 to the SMS gateway of Ethio Telecom. Then, the system
                           authenticates the user by checking the subscribed users from the ethi telecom sms server through API integration we 
                           have built into the system.  

                        </p>
                      <div className={vidMedia ? 'video-container active' : 'video-container'}>
                        <div className='video-cont'>
                          <video src='DemoHMS.mp4' id='video' controls />
                        </div>
                      
                        <div className='close' onClick={closeVideo}>
                            <i><FontAwesomeIcon icon={faClose} /></i>
                        </div>
                      </div> 
                    </div>
                               
                    <div className='card-footer'>
                    <button className='btn-primary' onClick={openVideo}> <FontAwesomeIcon icon={faVideo}/> Watch Demo</button>
                        <button className='btn view-detail'><FontAwesomeIcon icon={faGithub} /> Private Github Link</button>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-header'>
                        School Management System (on progress..)
                    </div>
                    <div className='card-body'>
                        <span className='title'>Tech stack used:</span>
                        <p>  EJS Templating Engine, CSS, Express JS, MySQL Database and MVC design pattern.</p>
                        <span className='title'>Features:</span>
                        <p> 
                        Managing students and staffs records. <br />   
                        Managing subjects, Grades and Classroom records. <br />   
                        Allowing parent to track their children’s academic and behavioral records.<br />   
                        Permitting File sharing such as books, demonstration videos and etc.<br />   
                        Generating automatic managerial and academic reports. <br />   
                        </p>
                        

                    </div>
                    <div className='card-footer'>
                        <button className='btn-primary' onClick={openVideo3}> <FontAwesomeIcon icon={faVideo} /> Watch Demo</button>
                        <button className='btn view-detail'><FontAwesomeIcon icon={faGithub} /> Private Github Link</button>
                    </div>
                </div>
               
               
            </div>
            <div className='card-container card2'>
                
                <div className='card'>
                    <div className='card-header'>
                        Nutrition and Physichal Fitness Content Servision Provision
                    </div>
                    <div className='card-body'>
                        <span className='title'>Tech stack used:</span>
                        <p>  EJS Templating Engine, CSS, Express JS, MySQL Database and MVC design pattern.</p>
                        <span className='title'>Features:</span>
                        <p> The system keeps track of Nutrition and Physichal Fitness video and blog contents compiled by professionals. This contents are accessible only 
                            for the subscribed users. The subscription of the users is conducted through SMS message sent to the Ethio Telecom 
                            SMS server through the users phone number. Then we verify the subsribed user by checking the availability of the phone no of the user on the SMS server of Ethio 
                            Telecom through the API call we have built into the system.
                           
                        </p>
                    </div>
                    <div className='card-footer'>
                        <button className='btn-primary' onClick={openVideo2}> <FontAwesomeIcon icon={faVideo} /> Watch Demo</button>
                        <button className='btn view-detail'><FontAwesomeIcon icon={faGithub} /> Private Github Link</button>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-header'>
                        Database Replication Project for Awash Bank
                    </div>
                    <div className='card-body'>
                        <span className='title'>DBMS used:</span>
                        <p>  Microsoft SQL Server</p>
                        <span className='title'>Features:</span>
                        <p> 
                            We have implemented transactional replication on the SQL server 2014.  
                        </p>
                        <br />
                        <a href='Database_replication.pdf' style={{color: 'blue', fontWeight: 'bold'}}>Open PDF</a>
                    </div>
                    
                </div>
             
            </div>
           
         </section>
        
    )
}
export default Portfolio1;