import './footer.css'
import { faFacebook, faGithub, faLinkedin, faTelegram, faTwitter} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-scroll';
const Footer = ()=>{
    const date = new Date();
    const year = date.getFullYear;
    return(
        <>
          <footer className="footer">
            <div className="footer-container">
                <div className="about" >
                    <div className='title'>
                      About
                    </div>
                    <div className='detail'>
                      This website is a personal portfolio website that show case the skills and previous works
                      of the developer.
                    </div>
                </div>
                <div className="links">
                    <div className='title'>
                       Links
                    </div>
                    <div className='link-items'>
                    <Link style={{cursor: 'pointer'}} to="hero" spy={true} smooth={true} offset={-60} duration={400} >Home</Link>
                    <Link style={{cursor: 'pointer'}} to="portfolio" spy={true} smooth={true} offset={-60} duration={400} >portfolio</Link>
                    <Link style={{cursor: 'pointer'}} to="skills" spy={true} smooth={true} offset={-60} duration={400} >Skills</Link>
                    <Link style={{cursor: 'pointer'}} to="contact" spy={true} smooth={true} offset={-60} duration={400} >Contact</Link>
                    
                    </div>
                </div>
                <div className="social">
                  <div className='title'>
                    Follow me
                  </div>
                  <div className='social-links'>
                       <i><a href="https://www.facebook.com/lati.tesfaye" ><FontAwesomeIcon icon={faFacebook} /></a></i>
                       <i><a href="https://www.github.com/letigit18" ><FontAwesomeIcon icon={faGithub} /></a></i>
                        <i><FontAwesomeIcon icon={faTwitter} /></i>
                        <i><FontAwesomeIcon icon={faTelegram} /></i>
                  </div>
                </div>
            </div>
          
            <div className="copyright">
            
                &copy; 2023 Powered by Letera Tesfaye. All rights reserved.
            </div>
          </footer>

        </>
    )
}
export default Footer;