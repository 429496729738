import './Profile.css'
const Profile = ()=>{
    return(
        <section className="profile-container">
            <div className="profile-title">
                <h2>Profile Summary</h2>
            </div>
            <div className='profile-detail'>
                My name is Letera Tesfaye. I graduated MSc. Degree in Computer Science from Addis Ababa university in 2019 and 
                I took BSc. Degree in Information Systems from Madda Welabu university in 2014. My professional journey started 
                since 2014 by being Lecturer in Wolaita Sodo university. During my stay in the university I have been participated in different research and Projects that
                solved the community problem. Besides, I have been
                worked in the Banking Industry for one year as Associate 
                Database Administrator in Awash Bank. During this time I have grasped deep technichal knowledge and soft skill knowlege that 
                would be the greatest foundation to my current expertise. Currently, I am working on developing full stack applications that solve problems
                of the community and enhances the organizational delivery of services by using emerging Tech. stacks.
            </div>
        </section>
    )
}
export default Profile;